/*
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2020-12-31 16:39:18
 * @LastEditors: hutian
 * @LastEditTime: 2021-02-03 10:02:07
 */
export const columns = [
    // {
    //     title: '序号',
    //     dataIndex: 'index',
    //     key: 'index',
    //     /*width:150,*/
    //     align:'center',
    //     ellipsis:true,
    //     scopedSlots: { customRender: 'index'}
    // },
    {
        title: '标签名称',
        dataIndex: 'tagName',
        key: 'tagName',
        align:'center',
        ellipsis:true,
        width: 200,
        fixed: 'left',
        scopedSlots: { customRender: 'name'}
    },
    {
        title: '标签图标',
        dataIndex: 'tagLogo',
        key: 'tagLogo',
        /*width:150,*/
        align:'center',
        ellipsis:true,
        scopedSlots: { customRender: 'tagLogo'}
    },
    {
        title: '商品数量',
        dataIndex: 'tagGoodsList.length',
        key: 'tagGoodsList.length',
        /*width:150,*/
        align:'center',
        ellipsis:true,
        scopedSlots: { customRender: 'proCount'}
    },
    {
        title: '标签说明',
        dataIndex: 'tagDesc',
        key: 'tagDesc',
        /*width:150,*/
        align:'center',
        ellipsis:true
    },
    {
        title: '状态',
        dataIndex: 'flagEnable',
        key: 'flagEnable',
        /*width:150,*/
        align:'center',
        ellipsis:true,
        scopedSlots: { customRender: 'flagEnable'}
    },
    {
        title: '操作时间',
        dataIndex: 'modifyTime',
        key: 'modifyTime',
        /*width:150,*/
        align:'center',
        width: 200,
        ellipsis:true
    },
    // {
    //     title: '操作',
    //     dataIndex: 'action',
    //     key: 'action',
    //     width:150,
    //     align:'center',
    //     ellipsis:true,
    //     scopedSlots: { customRender: 'action'}
    // }
]
